const PRODUCERS = {
  LAIKO: 'Laiko',
  CHEDDA: 'Chedda',
  PATRICK_SULLIVAN: 'Patrick Sullivan'
}

const WRITERS = {
  LAIKO: 'Jake LiBassi',
  CHEDDA: 'Michael Maggiore',
  PATRICK_SULLIVAN: 'Patrick Sullivan'
}

const ROOMS = {
  A: 'A Room',
  B: 'B Room',
  PROD: 'Production Room'
}

// We are just using the credits properties in text, removing underscore which is why they are capital
// keys are Shot_in, Production, Co_Written, Recorded_in

// TODO if there are multiple keys in credits object, only the last array is listed
let news = [
  {
    artist: 'Khary', // FIXME
    title: 'Fader Photoshoot in the A Room',
    date: 'November, 2018',
    img: '/images/webp/khary-fader.webp',
    link: "https://www.thefader.com/2018/11/19/khary-is-weird-and-here-to-stay-1",
    credits: { Shot_in: [ROOMS.A]}
  },
  {
    artist: 'Lion Babe',
    title: "𝘊𝘰𝘴𝘮𝘪𝘤 𝘞𝘪𝘯𝘥 is Out",
    date: 'March 2019',
    img: '/images/webp/cosmic-wind.webp',
    link: "spotify:album:61daGwqgh0MGK2MigtyVfv",
    credits: { Recorded_in: [ROOMS.A, ROOMS.PROD] }
  },
  {
    artist: 'Lophiile', //FIXME
    title: "Highsnobiety x Adidas Shoot in Studio B",
    date: 'April 2019',
    img: '/images/webp/lophiile-adidas.webp',
    link: "https://www.highsnobiety.com/p/lophiile/",
    credits: { Shot_in: [ROOMS.B]}
  },
  {
    artist: 'Julian Lamadrid', //FIXME
    title: "Inks a Deal with Arista Records",
    date: 'May 2019',
    img: '/images/webp/julianlama.webp',
    link: "https://www.billboard.com/articles/columns/pop/8512876/julian-lamadrid-signs-arista-records-exclusive",
    credits: { Produced_by: [PRODUCERS.LAIKO, PRODUCERS.CHEDDA] },
  },
  // {
  //   artist: 'Dababy', //FIXME
  //   title: "Genius Shoots a Deconstructed for 'Suge' in Studio A",
  //   credits: [],
  //   date: 'May 2019',
  //   img: '/images/webp/dababy-genius.webp',
  //   link: "https://www.youtube.com/watch?v=6ePW6A4JJak",
  //   credits: { Shot_in: [ROOMS.A]}
  // },
  {
    artist: 'mxmtoon x Cavetown', //FIXME
    title: "Spotify Releases '21 Days with mxmtoon' Podcast in Studio A",
    date: 'June 2019',
    img: '/images/webp/mxmtoon-cavetown.webp',
    link: "spotify:show:7k2gNSLZnopnh6pcvkQACK",
    credits: { Recorded_in: [ROOMS.A]},
  },
  // {
  //   artist: 'Too Many Zooz', //FIXME
  //   title: "Live Video Shoot for 'Black Ice' in Studio A",
  //   date: 'June 2019',
  //   img: '/images/webp/too-many-zooz.webp',
  //   link: "https://www.youtube.com/watch?v=FcF6Xq8Rosg",
  //   credits: { Shot_in: [ROOMS.A]}
  // },
  {
    artist: 'Julian Lamadrid',
    title: "'Mess' Lands on New Music Friday",
    date: 'June 2019',
    img: '/images/webp/mess-julian.webp',
    link: "spotify:track:6OEcTlp7hJcxvCCQWXzy1S",
    credits: { Produced_by: [PRODUCERS.LAIKO, PRODUCERS.CHEDDA] },
  },
  {
    artist: 'Marcos G', //FIXME
    title: "Live Video Shoot for 'Stay' in Studio A",
    date: 'July, 2019',
    img: '/images/webp/marcos-stay.webp',
    link: "https://www.youtube.com/watch?v=XRiJL5KWjho",
    credits: { Shot_in: [ROOMS.A]}
  },
  {
    artist: 'Julian Lamadrid', //FIXME
    title: "'Die Young' and 'Neon' Land on New Music Friday",
    date: 'August, 2019',
    img: '/images/webp/jules-die-young.webp',
    link: "spotify:track:4YNGKFYnZJYMpWajAeEmGy",
    credits: { Produced_by: [PRODUCERS.LAIKO, PRODUCERS.CHEDDA] }
  },
  {
    artist: 'Sophie Cates',
    title: "'Boys R Dumb! Duh!' Lands on New Music Friday",
    date: 'October, 2019',
    img: '/images/webp/boysrdumbduh.webp',
    link: "spotify:track:2fswhJOAJ4myANknmgOl5W",
    credits: { Produced_by: [PRODUCERS.LAIKO, ] }
  },
  {
    artist: 'Julian Lamadrid',
    title: "Julian's Debut Album, 𝘔𝘢𝘭𝘢 𝘕𝘰𝘤𝘩𝘦, is Released",
    credits: { Produced_by: [PRODUCERS.LAIKO, PRODUCERS.CHEDDA] },
    date: 'November, 2019',
    img: '/images/webp/mala-noche.webp',
    link: "spotify:album:171oupl4m8OYvNqFNaTFRG"
  },
  {
    artist: 'marcos g',
    title: "'dancefloor' Lands on New Music Friday",
    credits: { Produced_by: [] },
    date: 'November, 2019',
    img: '/images/webp/marcosg.webp',
    link: "spotify:track:4dRCiK2BKegCgVDXtfZYzP"
  },
  {
    artist: 'Dounia',
    title: "'My Own Company' Lands on New Music Friday",
    credits: { Produced_by: [PRODUCERS.LAIKO] },
    date: 'November, 2019',
    img: '/images/webp/myowncompany.webp',
    link: "spotify:track:4Q6hxbRrNlSa4hyx04IyEA"
  },
  {
    artist: 'Sophie Cates',
    title: "Paper Mag Lists 'Boys R Dumb! Duh! as #45 Song of The Year'",
    date: 'December, 2019',
    img: '/images/webp/boysrdumbduh.webp  ',
    link: "https://www.papermag.com/paper-top-50-songs-2019-2641607112.html?rebelltitem=25",
    credits: { Produced_by: [PRODUCERS.LAIKO, PRODUCERS.CHEDDA] }
  },
  {
    artist: 'Slingbaum', //FIXME
    title: "Slingbaum Releases 𝘚𝘭𝘪𝘯𝘨𝘣𝘢𝘶𝘮 𝘖𝘯𝘦",
    date: 'January, 2020',
    img: '/images/webp/slingbaum.webp',
    link: "https://pitchfork.com/news/slingbaum-announces-new-album-with-dangelo-erykah-badu-fka-twigs-more/",
    credits: { Recorded_in: [ROOMS.A]}
  },
  {
    artist: 'Dounia',
    title: "'Mars or Maui' Lands on New Music Friday",
    date: 'February, 2020',
    img: '/images/webp/mars-or-maui.webp',
    link: "spotify:track:48EgjNzxFSFJzeUzd59RJc",
    credits: { Produced_by: [PRODUCERS.LAIKO] }
  },
  {
    artist: 'Julian Lamadrid',
    title: "'My Time' Lands on New Music Friday",
    date: 'February, 2020',
    img: '/images/webp/my-time-julian.webp',
    link: "spotify:track:7bbphNnvNMrOym1PvjHaSu",
    credits: { Produced_by: [PRODUCERS.LAIKO, PRODUCERS.CHEDDA] },
  },
  {
    artist: 'Hadji Gaviota',
    title: "'RUNNER\'s HIGH' featured on Major Stage",
    date: 'May, 2020',
    img: '/images/webp/runners-high-hadji.webp',
    link: "spotify:track:4KknH3lodxYo2gZAnU38O4",
    credits: { Produced_by: [PRODUCERS.CHEDDA, PRODUCERS.PATRICK_SULLIVAN]},
  },
  {
    artist: 'Sophie Cates',
    title: "Silver Sphere Signs to RCA",
    date: 'July, 2020',
    img: '/images/webp/silversphere.webp',
    link: "https://www.rcarecords.com/artist/silver-sphere/",
    credits: { Produced_by: [PRODUCERS.LAIKO]},
  },
  {
    artist: 'Laiko',
    title: "Laiko Releases 'Strange Attractors'",
    date: 'August, 2020',
    img: '/images/webp/strangeattractors.webp',
    link: "spotify:album:4EVjJ0YwiT3UpRUdLOM0C2",
    credits: { Produced_by: [PRODUCERS.LAIKO]},
  },
  {
    artist: 'Laiko',
    title: "'In the Light' gets on Chill Vibes",
    date: 'August, 2020',
    img: '/images/webp/inthelight.webp',
    link: "spotify:album:4EVjJ0YwiT3UpRUdLOM0C2",
    credits: { Produced_by: [PRODUCERS.LAIKO]},
  },
  {
    artist: 'Hadji Gaviota',
    title: "'Catch These Hands' lands on Fresh Finds",
    date: 'October, 2020',
    img: '/images/webp/catchthesehands.webp',
    link: "spotify:track:0toTWyZVmhDp9R9HRvdP1A",
    credits: { Produced_by: [PRODUCERS.CHEDDA, PRODUCERS.PATRICK_SULLIVAN]},
  },
  {
    artist: 'min.a',
    title: "'Hour 24' on Indie Pop",
    date: 'October, 2020',
    img: '/images/webp/hour24.webp',
    link: "spotify:album:7yLa6nQt9j15F3YyMGjAdw",
    credits: { Produced_by: [PRODUCERS.LAIKO]},
  },
  {
    artist: 'Peter McPoland',
    title: "Eloise Gets Fresh Finds!",
    date: 'January, 2021',
    img: '/images/webp/pm-eloise.webp',
    link: "spotify:track:5QYSGcJw2lamJY3VkL5W2m",
    credits: { Produced_by: [PRODUCERS.LAIKO] },
  },
  {
    artist: 'Richie Quake',
    title: "'Sensitive' gets on Lorem",
    date: 'February, 2021',
    img: '/images/webp/richie-sensitive.webp',
    link: "spotify:track:1XbyfNSAe9UHBjn22YJ7Ys",
    credits: { Produced_by: [PRODUCERS.CHEDDA]},
  },
  {
    artist: 'The Other Two',
    title: "Films Episode in A Room",
    date: 'February, 2021',
    img: '/images/webp/other-two.webp',
    link: "",
    credits: { Filmed_in: ['Studio A'] },
  }, // dee
  {
    artist: 'min.a',
    title: "'Everyone Is Greedy' secures on All New Indie",
    date: 'March, 2021',
    img: '/images/webp/everyone-is-greedy.webp',
    link: "spotify:track:2O7ULFEXxJKqTzm2xmdLgL",
    credits: { Produced_by: [PRODUCERS.LAIKO]},
  },
  {
    artist: 'Alisa Nappa',
    title: "'Goodnight (ILY)' lands on Early Rising",
    date: 'May, 2021',
    img: '/images/webp/goodnight-ily-an.webp',
    link: "spotify:track:0YR1Xdmi4E0wbhQHmzeog8",
    credits: { Produced_by: [PRODUCERS.PATRICK_SULLIVAN]},
  },
  {
    artist: 'Cautious Clay',
    title: "Soundtrack for 'Godfather of Harlem' tracked in Studio A",
    date: 'June, 2021',
    img: '/images/webp/godfather-of-harlem.webp',
    link: "spotify:artist:6iWuBN32BqCJAeXW6o3nil",
    credits: { Recorded_in: ['Studio A'] },
  },
  {
    artist: 'Suzuki Saint',
    title: "Suzuki Saint Releases Visuals for Otherside",
    date: 'July, 2021',
    img: '/images/webp/ss-otherside.webp',
    link: "https://www.youtube.com/watch?v=jczlZ7FG7mA",
    credits: { Produced_by: [PRODUCERS.LAIKO]}
  },
  {
    artist: 'Cece Coakley',
    title: "'Cliché' lands on Folk Pop",
    date: 'July, 2021',
    img: '/images/webp/cliche-cece.webp',
    link: "spotify:album:7fL5UDmFr1IdZH3DNP5xhG",
    credits: { Produced_by: [PRODUCERS.LAIKO]}
  },
  {
    artist: 'Jeremy Zucker',
    title: "Releases Album 'Crusher'",
    date: 'October, 2021',
    img: '/images/webp/crusher-album-artwork.webp',
    link: "spotify:album:6pEz5WCvDGB8ved9AcouQ5",
    credits: { Recorded_in: [ROOMS.PROD]}
  },
  {
    artist: 'Nora From Queens',
    title: "Films The Best Episode In The A Room. We Love Awkwafina!",
    date: 'November, 2021',
    img: '/images/webp/nora.webp',
    link: "https://www.youtube.com/watch?v=FRANi3-EK50",
    credits: { Filmed_in: [ROOMS.A]}
  },
  {
    artist: 'Richie Quake',
    title: "Richie Quake Signs To Mass Apeal",
    date: 'November, 2021',
    img: '/images/webp/quake.webp',
    link: "spotify:artist:5RmZclxIWvmctw5DNBJyo6",
    credits: { Produced_by: [PRODUCERS.CHEDDA]}
  },
  {
    artist: 'Ben Kessler',
    title: "'April' gets on NMF",
    date: 'March, 2022',
    img: '/images/webp/april-ben-kessler.webp',
    link: "spotify:track:3izibnOkKCvSjKWYXAS2BO",
    credits: { Produced_by: [PRODUCERS.LAIKO]}
  },
  {
    artist: 'Bohan Pheonix',
    title: "Bohan Pheonix Performs Clippers vs Bucks Halftime Show For Lunar New Year",
    date: 'February, 2022',
    img: '/images/webp/bohan-nba.webp',
    link: "https://www.youtube.com/watch?v=msAloiYcAGc",
    credits: { Produced_by: [PRODUCERS.CHEDDA]}
  },
  {
    artist: 'Laiko',
    title: "'Same Plot' lands on Fresh Finds Pop",
    date: 'March, 2022',
    img: '/images/webp/same-plot-laiko.webp',
    link: "spotify:album:25XUt5RUrgUDYtHixVNzeF",
    credits: { Produced_by: [PRODUCERS.LAIKO]}
  },
  {
    artist: `Medium Build`,
    title: `'comeonback' gets NPR coverage`,
    date: 'June, 2022',
    img: '/images/webp/medium-build-comeonback.webp',
    link: "https://www.npr.org/sections/now-playing/2022/06/09/1103911678/medium-build-comeonback",
    credits: { Produced_by: [PRODUCERS.LAIKO]}
  },
  {
    artist: `Anti Social Producer's Club`,
    title: "Hosted At Shifted",
    date: 'June, 2022',
    img: '/images/anti-social-camp.jpeg',
    link: "https://www.antisocialproducersclub.com/",
    credits: { Produced_by: [PRODUCERS.LAIKO, PRODUCERS.CHEDDA]}
  },
  {
    artist: `Sydney Rose`,
    title: `'Cutting Corners' on New Music Friday`,
    date: 'July, 2022',
    img: '/images/webp/sydney-rose-cc.webp',
    link: "spotify:track:6AN3OGCZP8a7935tvYrgDD",
    credits: { Produced_by: [PRODUCERS.LAIKO]}
  },
  {
    artist: `Bel`,
    title: `'Lights' makes Spotify's undercurrents playlist`,
    date: 'July, 2022',
    img: '/images/webp/bel-lights.webp',
    link: "spotify:track:28PHxtY7jvJ8ZYOKo3ZduD",
    credits: { Produced_by: [PRODUCERS.LAIKO]}
  },
  {
    artist: `Richie Quake`,
    title: `'I Want Some' released on Mass Appeal`,
    date: 'July, 2022',
    img: '/images/webp/i-want-some-artwork.webp',
    link: "spotify:album:3Q6dlnwZy0GBBN0zhJcanY",
    credits: { Mixed_by: [PRODUCERS.CHEDDA]}
  },
  {
    artist: `Bliss Samsa`,
    title: `'Father Figure' makes Anti Pop`,
    date: 'August, 2022',
    img: '/images/webp/bliss-samsa-ff.webp',
    link: "spotify:track:6RZXC8TU0PvdcDfPbtkV10",
    credits: { Produced_by: [PRODUCERS.LAIKO]}
  },
  // mxmtoon (when it comes out) Artist Den
  // Law and Order films episode February
  // Cliché Cece Coakley Folk Pop July 2021
];

news.reverse();
export default news
