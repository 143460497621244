import React from 'react'
import { StyleSheet, css } from 'aphrodite';
import MailModal from '../modals/MailModal.js';

const TIMER = 4000
class Slider extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      index: 0,
    };
  }

  renderDots(){
    if(!this.props.room) return null
    return this.props.room.slider.map((rm, i) =>{
      return <div key={`dot-${i}`}className={css(styles.dot)}style={{background: this.state.index === i ? 'white' : 'transparent'}}></div>
    })
  }

  componentDidMount(){
    setTimeout(() => {
      if(this.refs.roomslider){
        this.setState({index: 1})
      } else {
        return;
      }
    }, TIMER)
  }
  componentDidUpdate(){
    let nextIndex = this.state.index  === this.props.room.slider.length - 1 ? 0 : this.state.index + 1;
    setTimeout(() => {
      if(this.refs.roomslider){
        this.setState({index: nextIndex})
      } else {
        return;
      }
    }, TIMER)
  }
  componentWillUnmount(){
    clearInterval('timer');
  }
  render(){
    let dots = this.renderDots()
    return (
      <div ref='roomslider' className={css(styles.full)}>
        { this.props.room
          ?
          <div>
            <div className={css(styles.overlay)}>
              <h2 className={css(styles.title)}> { this.props.room.slider[this.state.index].caption }</h2>
                <MailModal buttonText={`Let's Talk`} stylesKey={`room`}/>
            </div>
            <img className={css(styles.img)} src={this.props.room.slider[this.state.index].file} />
          </div>
          :
          null
        }
        <div className={css(styles.dotsContainer)}> {dots} </div>
      </div>
    )
  }
}

const styles = StyleSheet.create({
    wrapper: {
      margin: 0,
      padding: 0
    },
    full:{
      width: '100%',
      height: '100vh',
    },
    img: {
      width: '100%',
      height: '100vh',
      objectFit: 'cover'
    },
    overlay: {
      position: 'absolute',
      width: '100%',
      top: '50%',
      transform: 'translateY(-50%)'
    },
    title: {
      margin: 0,
      width: '100%',
      textAlign: 'center',
      color: 'white',
      fontFamily: 'KnockoutBan',
      fontWeight: 400,
      letterSpacing: '2px',
      fontSize: '72px',
      textTransform: 'uppercase',
      textAlign: 'center',
      position: 'relative',
    },
    dot: {
      height: 10,
      width: 10,
      borderRadius: '10px',
      border: '2px solid white',
      margin: 10,
    },
    dotsContainer: {
      position: 'absolute',
      display: 'flex',
      bottom: '100px',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'center'
    }
});
export default Slider
