// Imports
import React from 'react';
import { Link } from 'react-router-dom';
import Blogs from './entries/index.js';
import Impact from './../home/Impact.js';
import { StyleSheet, css } from 'aphrodite';
import styles from './blog-styles.js';
import { Helmet } from 'react-helmet';

// Component
const BlogContainer = ({ match: { params: { blog } } }) => {
  // render
  //let RoomObject = RoomConstants.find(obj => obj.url === room )

  let BlogObject = Blogs.find(obj => obj.url === blog );
  console.log('Do We Have The Blog?', BlogObject)
  return (
    <div className={css(styles.blogsection)}>
      <Helmet>
      </Helmet>
      <Impact context='blog'/>
      {BlogObject.jsx()}
    </div>
  )
}

export default BlogContainer
