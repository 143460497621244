import React from 'react';
import { Helmet } from 'react-helmet';
import { StyleSheet, css } from 'aphrodite';
import MailModal from '../modals/MailModal.js'



function Project () {
  return (
    <div className={css(styles.project)}>
      <MailModal buttonText={`Tell Us About Your Project`} stylesKey={`project`} />
    </div>
  )
}
const styles = StyleSheet.create({
    project: {
      height: "300px",
      background: "#001824",
      background: 'linear-gradient(90deg, #001824 0%, #4c7c5f 100%, #0021ff 100%)',
      display: "flex",
      alignSelf: "center",
      flexDirection: 'column',
      alignItems: "center",
      justifyContent: "center",
    },
    disco: {
      marginTop: 20,
      color: 'white',
      fontFamily: 'Akkurat',
      fontSize: "18px",
    },
    button: {
      background: "rgba(0, 0, 0, 0.3)",
      border: "1px solid white",
      fontFamily: "Akkurat",
      fontSize: "18px",
      borderRadius: "4px",
      color: "white",
      height: "60px",
      width: "300px",
      cursor: 'pointer'
    }
});

export default Project
