import React from 'react'
import { Helmet } from 'react-helmet'
import { StyleSheet, css } from 'aphrodite';
const Discord = () => (
  <div id='disco' className={css(styles.disco)}>
    <a href='https://discord.gg/y7MUXdP7Kj'>
      <p className={css(styles.txt)}>
        Also! Join our new community on Discord!
      </p>
      <img src="images/webp/discord.webp" width={53} height={60} alt='discord link' className={css(styles.img)}/>
    </a>
  </div>
)

const styles = StyleSheet.create({
    txt: {
      marginTop: 20,
      color: 'white',
      fontFamily: 'Akkurat',
      fontSize: "18px",
    },
    img: {
      display: 'block',
      margin: '0 auto',
    },
    disco: {
      background: "#5f4c7c",
      //background: 'linear-gradient(90deg, #001824 0%, #4c7c5f 100%, #0021ff 100%)',
      display: "flex",
      padding: 50,
      textAlign: 'center',
      alignSelf: "center",
      flexDirection: 'column',
      alignItems: "center",
      justifyContent: "center",
    },
});

export default Discord
