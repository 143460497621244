import React from 'react'
import { Helmet } from 'react-helmet'
import { StyleSheet, css } from 'aphrodite';
const GearList = () => (
  <a target="_blank" href="/gear-list.pdf" className={css(styles.gearlist)}>
    View Gearlist
  </a>
)
const styles = StyleSheet.create({
    gearlist: {
      background: 'white',
      color: "#4c7c5f",
      fontSize: "150%",
      margin: "0 auto",
      paddingBottom: "20px",
      width: "100%",
      textAlign: "center",
      display: "block",
      textDecoration: 'none',
      fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif"
    }
});

export default GearList
