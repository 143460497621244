// Imports
import React from 'react'
import { Helmet } from 'react-helmet'

const Blog = () => {
  return (
    <div>
      <Helmet>
      </Helmet>

      <h1> Richie Quake </h1>

      <p> This is the actual blog post </p>
    </div>
  )
}
export default Blog;
