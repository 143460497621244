import { StyleSheet, css } from 'aphrodite';

const styles = StyleSheet.create({
    blogbanner: {
      background: '#7C4C69',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '100%',
      backgroundSize: 'cover',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      height: '150px'
    },
    image: {
      width: 150,
      margin: '0 auto',
      display: 'block',
    },
    button: {
      background: "rgba(0, 0, 0, 0.3)",
      border: "1px solid white",
      fontFamily: "Akkurat",
      fontSize: "18px",
      borderRadius: "4px",
      color: "white",
      height: "60px",
      width: "300px",
      cursor: 'pointer'
    },
    rbContainer: {
      //background: '#7C4C69',
      // height: '60vh',
      width: "80%",
      margin: "0 auto",
      '@media (max-width: 1080px)': {
        width: "90%",
      },

    },
    rbHeader: {
      margin: 50,
      textAlign: 'center',
      // marginBottom: 50,
    },
    blogsection: {
      // height: '100vh',
      background: `url(${'/images/webp/nice-bg.webp'})`
    },
    entriesContainer: {
      textAlign: 'center',
    },
    entry: {
      display: 'inline-flex',
      border: '1px solid black',
      fontFamily: 'Akkurat',
      marginBottom: 25,
      '@media (max-width: 768px)': {
        flexDirection: 'column',
        width: 400,
        background: 'white'
      },
      '@media (max-width: 420px)': {
        flexDirection: 'column',
        width: '100%',
        background: 'white'
      },
    },
    entryimg: {
      width: '200px',
      minWidth: '200px',
      height: 200,
      '@media (max-width: 768px)': {
        width: '400px',
        height: '400px',
      },
      '@media (max-width: 768px)': {
        width: '100%',
        aspectRatio: '1 / 1',
        height: 'auto',

      },
    },
    border: {
      width: 'calc(100% - 20px)',
      height: 'calc(100% - 20px)',
      border: '10px solid white',
      '@media (min-width: 768px)': {
        display: 'none',
      },
    },
    entryTextGroup: {
      marginLeft: 20,
      display: 'flex',
      flexDirection: 'column'
    },
    entryHR: {
      width: 'calc(100% - 20px)',
      position: 'relative',
      right: '10px'
    },
    entryHeader: {
      marginTop: 5,
      marginBottom: 5,
      textTransform: 'uppercase',
      fontFamily: 'KnockoutBan',
      letterSpacing: '1px'
    },
    entrySynop: {
      margin: 0,
      paddingRight: 10,
      '@media (max-width: 768px)': {
        paddingBottom: 20,
      },
    },
    entrySynopContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    entryDate: {
      margin: 0,
      fontStyle: 'italic',
      fontSize: '12px',
    }
});

export default styles;
