import React from 'react'
import { Helmet } from 'react-helmet'
import { StyleSheet, css } from 'aphrodite/no-important';
import RoomConstants from './../constants/rooms.constant.js';
import { Link } from 'react-router-dom';
const MapRooms = () => {
  return RoomConstants.map((rm, i) => {
    return (
      <div className={css(styles.room)} key={`rooms-${i}`}>
        <div className={css(styles.divider)}> </div>
        <div className={css(styles.darkOverlay)}> </div>
        <img className={css(styles.image)} src={rm.img} alt={rm.title} />
        <div className={css(styles.info)}>
          <div className={css(styles.number)}> {i} </div>
          <div className={css(styles.title)}> {rm.title} </div>
        </div>
        <Link to={`/rooms/${rm.url}`}><button className={css(styles.button)}> View {rm.title} </button> </Link>
      </div>
    )
  })
}

const Rooms = () => {
  let rms = MapRooms();
  return (
    <div className={css(styles.wrapper)}>
      {rms}
    </div>
  )
}

const styles = StyleSheet.create({
    wrapper: {
        background: 'white',
        position: "relative",
        bottom: "0px",
        paddingTop: "20px",
        width: "100%",
        height: "400px",
        display: "flex",
        '@media (max-width: 767px)': {
            display: 'table',
        },
        zIndex: 0,
        textAlign: "center",
    },
    room: {
      height: "400px",
      width: "33%",
      '@media (max-width: 767px)': {
          width: '96%',
          margin: '0 auto',
          marginTop: 5,
      },
      display: "inline-block",
      backgroundSize: "cover",
      position: "relative",
    },
    darkOverlay: {
      width: "100%",
      height: "100%",
      zIndex: 1,
      position: "absolute",
      backgroundColor:" rgba(0, 0, 0, 0.4)",
      top: 0,
      left: 0,
    },
    image: {
      left: "0",
      width: "100%",
      position: "absolute",
      height: "100%",
      objectFit: "cover",
    },
    divider: {
      width: '4px',
      height: '100%',
      backgroundColor: 'white',
      position: 'absolute',
      zIndex: 4,
      '@media (max-width: 767px)': {
          width: 0,
          display: 'none',
      },
    },
    info: {
      position: "relative",
      zIndex: 2,
      // paddingLeft: "20px",
      fontFamily: "KnockoutBan",
      color: "white",
    },
    title: {
      fontFamily: "Akkurat",
      fontWeight: "400",
      fontSize: "36px",
    },
    number: {
      border: "1px dashed white",
      borderRadius: "90px",
      height: "45px",
      width: "45px",
      lineHeight: "42px",
      margin: "30px auto",
      textAlign: "center",
    },
    button: {
      cursor: 'pointer',
      position: "absolute",
      fontFamily: "KnockoutBan",
      letterSpacing: "3px",
      textTransform: "uppercase",
      padding: "10px",
      bottom: "20px",
      right: 0,
      left: 0,
      width: "calc(100% - 40px)",
      margin: "auto",
      background: "white",
      color: "black",
      zIndex: 3,
      border: 0,
    }
});

export default Rooms
