import React from 'react'
import { Helmet } from 'react-helmet'
import { StyleSheet, css } from 'aphrodite';
const Space = () => (
  <div id='space' className={css(styles.wrapper)}>
    <h1 className={css(styles.space)}> Space </h1>
    <article className={css(styles.text)}>
      Shifted Recording was completed in 2016, with design led by Mark Genfan (Skyline, Orb Recording)
      and additional input from Phil Joly (Head Engineer, Electric Lady) and Frank Comentale (Hit Factory NY, Chung King).
      Our facility features two separate
      <h1 className={css(styles.kw)}> recording studio</h1>s as well as two
      <h1 className={css(styles.kw)}> music production rooms </h1>
      and a spacious lounge. Throughout the building, we've infused a variety of plant life to foster a creative environment
      like no other in New York. Complete with a vast collection of musical and recording tools,
      Shifted Recording represents a paradigm shift in what it means to be the modern recording studio for the modern artist.
    </article>
  </div>
)
const styles = StyleSheet.create({
    wrapper: {
      background: "white",
      padding: "50px",
    },
    space: {
      color: "black",
      fontFamily: "Akkurat",
      fontWeight: "400",
      fontSize: "36px",
      textAlign: "center",
      textTransform: 'Uppercase',
      margin: 0,
    },
    kw: {
      display: "inline !important",
      fontSize: "16px",
      fontWeight: "normal",
      width: "auto",
      fontFamily: "Akkurat",
    },
    text: {
      margin: "0 auto",
      textAlign: 'center',
      width: "50%",
      color: "black",
      fontFamily: "Akkurat",
      fontWeight: "400px",
      '@media (max-width: 767px)': {
          width: '100%',
      }
    }
});

export default Space
