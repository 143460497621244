export default [
    {
      title: 'Studio A',
      img: 'images/webp/new_studio-a-control.webp',
      overview: 'OUR LARGEST STUDIO, FEATURING A 360 SF LIVE ROOM AND AN ADDITIONAL ISO BOOTH / SOUND LOCK. STUDIO A IS HOME TO A VINTAGE NEVE 8108 AND IS EQUIPPED WITH A WIDE VARIETY OF CREATIVE TOOLS BOTH FAMILIAR AND UNIQUE TO ACCOMMODATE ANY ENGINEER OR ARTIST.',
      specs: { control_room: '370 SF', live_room: '360 SF', 'iso_booth': '40 SF' },
      gear: ['2016 Mac Pro', '32 Channel Neve 8108', 'UA 6176', 'Chandler Germanium (2)', 'AVALON 737SP', 'TubeTech CL 1B', 'Warm Audio Tonebeast', 'Warm Audio EQP-WA (2)'],
      glanceString: 'At a Glance',
      url: 'studio-a',
      slider: [
        { file: '../images/webp/new_studio-a-control-big.webp', alt: 'Studio A Control Room', caption: 'Control Room' },
        { file: '../images/webp/new_studio-a-live.webp', alt: 'Studio A Live Room', caption: 'Live Room', pano: true }
      ]
    },
    {
      title: 'Studio B',
      img: 'images/webp/new_studio-b-control.webp',
      overview: 'STUDIO B FEATURES A SPACIOUS COMFORTABLE CONTROL ROOM AND SPACIOUS ISO BOOTH. IT IS EQUIPPED WITH AN EXPANSIVE COLLECTION OF DIGITAL AND ANALOG CREATIVE TOOLS.',
      specs: { control_room: '190 SF' ,  iso_booth: '100 sqft' },
      gear: ['2016 IMAC','Moog Voyager', 'MOTIF ES8', 'VP28 Series 500'],
      glanceString: 'At a Glance',
      url: 'studio-b',
      slider: [
        { file: '../images/webp/new_studio-b-control-big.webp', alt: 'Studio B Control Room', caption: 'Control Room' },
        { file: '../images/webp/b-booth.webp', alt: 'Studio B Booth', caption: 'B Booth' }
      ]
    },
    {
      title: 'Monthly Production Rooms',
      img: 'images/webp/studio-c.webp',
      overview: 'WE HAVE TWO MONTHLY PRODUCTION ROOMS AVAILABLE FOR MONTHLY RENT. 24HR ACCESS',
      specs: { control_room: '190 SF' },
      gear: ['Apollo 8p', '2020 Macbook Pro', 'Various Monitors'],
      glanceString: 'At a Glance',
      url: 'production',
      slider: [
        { file: '../images/webp/studio-c.webp', alt: 'Studio C Control Room', caption: 'Writing Rooms' },
        { file: '../images/webp/studio-c-2.webp', alt: 'Studio C Booth', caption: '24HR ACCESS' }
      ]
    },
    {
      title: 'Lounge',
      img: 'images/webp/lounge.webp',
      overview: "We built a comfortable space.  It's important to feel relaxed when making music. If you need to take a break, feel free to grab a coffee or beer, play any video game pre 2004 on our Raspberry Pi, watch anything on TV, or read a book or magazine.",
      specs: { ceiling: '13ft' ,  sqft: '400 sqft' },
      glanceString: 'Lounge',
      url: 'lounge',
      slider:[
        { file: '../images/webp/gallery.webp', caption: 'Come On In.'  },
        { file: '../images/webp/beer.webp', caption: 'Grab a Coffee or Beer.'  },
        { file: '../images/webp/lounge.webp', caption: 'Welcome Home.'  }
      ]
    }
    // {
    //   title: 'Production Suites',
    //   img: 'images/webp/puppy_pad.webp',
    //   overview: 'TWO PRODUCTION SUITES AVAILABLE FOR WEEKLY OR MONTHLY LEASE. A GREAT UTILITY FOR PRODUCERS IN THE CITY OR A BAND THAT NEEDS A HOME.',
    //   specs: { SUITES: '180 SF'},
    //   gear: ['Bring your own!'],
    //   fact: '24HR ACCESS AND PRIVATE BATHROM',
    //   glanceString: 'Production Suites',
    //   state: 'production-suite',
    //   slider: []
    // }
  ];
