// Imports
import React from 'react'

// App Imports
import Home from '../components/home/Home'
import Room from '../components/rooms/Room'
import BlogsContainer from '../components/blog/BlogsContainer.js'
import BlogContainer from '../components/blog/BlogContainer.js'

// Routes
const routes = [
  {
    path: '/',
    component: Home,
    exact: true
  },
  {
    path: '/rooms/:room',
    component: Room
  },
  {
    path: "/blog/:blog",
    component: BlogContainer
  },
  {
    path: "/blog",
    component: BlogsContainer
  }
]

export default routes
