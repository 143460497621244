import React from 'react'
import { Helmet } from 'react-helmet'
import { StyleSheet, css } from 'aphrodite';
import CommunityConstant from '../constants/community.constant.js';

const Community = () => (
  <div id='community' className={css(styles.wrapper)}>
    <h1 className={css(styles.header)}> Community </h1>
    <ul className={css(styles.list)}>
      { CommunityConstant.map((member, i) =>
        <li key={`member-${i}`}className={css(styles.member)}> <i className="fa fa-caret-right fancy" />{member.name} </li>
      )}
    </ul>
  </div>
)
const styles = StyleSheet.create({
    wrapper: {
      background: "white",
      color: "black",
      padding: "50px",
      display: "flex",
      fontFamily: "Akkurat",
      fontWeight: 400,
      textTransform: "uppercase",
      flexDirection: "column",
      '@media (max-width: 767px)': {
          paddingRight: '0px',
      },
    },
    header: {
      color: "black",
      textAlign: "center",
      fontFamily: "Akkurat",
      fontWeight: 400,
      fontSize: "36px",
      '@media (max-width: 767px)': {
          position: 'relative',
          left: -25
      },
    },
    list: {
      padding: "30px 0px 0px 12.5%",
      '@media (max-width: 767px)': {
          padding: '0px',
      },
    },
    member: {
      position: "relative",
      listStyleType: "none",
      width: "25%",
      '@media (max-width: 767px)': {
          width: '50%',
          fontSize: 13,
          //display: 'block',
      },
      display: "inline-block",
    },
});

export default Community
