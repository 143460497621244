// Imports
import React from 'react'
import { Helmet } from 'react-helmet'
import { StyleSheet, css } from 'aphrodite';
import { Link } from 'react-router-dom';

import styles from './blog-styles.js';
import index from './entries/index.js';
function createRecents() {
  // console.log('index', index);
  return index.map((entry, idx) => {
    return (
      <Link to={`/blog/${entry.url}`} key={`blog-${idx}`} className={css(styles.entry)}>
        <div className={css(styles.entryimg)} style={{backgroundImage: `url(/images/webp/${entry.thumb})`}}> <div className={css(styles.border)}></div></div>
        <div className={css(styles.entryTextGroup)}>
        <h1 className={css(styles.entryHeader)}> { entry.name } </h1>
          <p className={css(styles.entryDate)}> { entry.date } </p>
          <hr className={css(styles.entryHR)}/>
          <div className={css(styles.entrySynopContainer)}>
            <p className={css(styles.entrySynop)}> { entry.synop } </p>
          </div>
        </div>
      </Link>
    )
    //return entry.jsx()
  })
}

const RecentBlogs = () => {
  let recents = createRecents();
  return (
    <div className={css(styles.rbContainer)}>
      <h1 className={css(styles.rbHeader)}>Recent Postings</h1>
      <div className={css(styles.entriesContainer)}> { recents  } </div>
    </div>
  )
}

export default RecentBlogs
