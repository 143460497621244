// Imports
import React from 'react'
import { Route, Switch, BrowserRouter } from 'react-router-dom'

// App Imports
import routes from '../setup/routes'
import Layout from './common/Layout'
import NotFound from './common/NotFound'
import { Provider } from 'react-redux'
import { store } from  '../setup/store'

const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <Layout>
        <Switch>
          {routes.map((route, index) => (
            // pass in the initialData from the server for this specific route
            <Route {...route} key={index}/>
          ))}

          <Route component={NotFound}/>
        </Switch>
      </Layout>
    </BrowserRouter>
  </Provider>
)

export default App
