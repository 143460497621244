import RQB0 from './richie-quake.js';
export default [
    {
      name: 'Richie Quake Does Stuff At Shifted',
      thumb: 'richie-sensitive.webp',
      synop: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
      jsx: RQB0,
      date: 'August 16th, 2022',
      url: 'richie-quake'
    },
    {
      name: 'Richie Quake Does Stuff At Shifted',
      thumb: 'richie-sensitive.webp',
      synop: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
      jsx: RQB0,
      date: 'August 16th, 2022',
      url: 'richie-quake'
    },
    {
      name: 'Richie Quake Does Stuff At Shifted',
      thumb: 'richie-sensitive.webp',
      synop: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
      jsx: RQB0,
      date: 'August 16th, 2022',
      url: 'richie-quake'
    },
    {
      name: 'Richie Quake Does Stuff At Shifted',
      thumb: 'richie-sensitive.webp',
      synop: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
      jsx: RQB0,
      date: 'August 16th, 2022',
      url: 'richie-quake'
    }
  ]
