import React from 'react';
import { Helmet } from 'react-helmet';
import { StyleSheet, css } from 'aphrodite';
import Modal from 'react-modal';
import MailForm from './MailForm';

Modal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,.75)';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: "#f0f0f0",
    borderRadius: "5px",
    color: "#444",
    fontFamily: "Helvetica,sans-serif",
    fontSize: "1.1em",
    lineHeight: "1.5em",
    // margin: "0 auto",
    maxWidth: "100%",
    padding: "1em",
    // position:"relative",
    width: "450px"
  },
};


const MailModal = (props) => {
  let appElement
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    // gtag('event', 'mail-modal-open', {
    //   'event_category': 'lead',
    //   'event_label': 'mail-open'
    // });
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal(status) {
    if(status){
      // Closing after actually sending an Email
    } else {
      // gtag('event', 'mail-modal-abandonded', {
      //   'event_category': 'lost',
      //   'event_label': 'mail-abandonded'
      // });
    }
    setIsOpen(false);
  }
  if(typeof document !== 'undefined') appElement = document.getElementById('app')
  return (
    <div className={css(styles.modalbuttonwrapper)}>
      <button onClick={openModal} className={css(styles[`${props.stylesKey}`])}> { props.buttonText } </button>
      <Modal
          appElement={appElement}
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
      >
          <MailForm closeModal={closeModal}/>
      </Modal>
    </div>
  )
}
const styles = StyleSheet.create({
    modalbuttonwrapper: {
      textAlign: 'center'
    },
    project: {
      background: "rgba(0, 0, 0, 0.3)",
      border: "1px solid white",
      fontFamily: "Akkurat",
      fontSize: "18px",
      borderRadius: "4px",
      color: "white",
      height: "50px",
      width: "300px",
      cursor: 'pointer'
    },
    room: {
      background: 'white',
      border: 0,
      position: 'relative',
      zIndex: 9,
      color: 'black',
      display: 'block',
      margin: '0 auto',
      padding: '10px',
      marginTop: '20px',
      fontFamily: 'KnockoutBan',
      letterSpacing: '2px',
      fontSize: '18px',
      textTransform: 'uppercase'

    }
});

export default MailModal
