import React from 'react'
import { Helmet } from 'react-helmet'
import { StyleSheet, css } from 'aphrodite';

const Footer = () => (
  <div className={css(styles.wrapper)}>
    <ul className={css(styles.mobileFull)}>
      <li className={css(styles.primary)}> Let's Talk </li>
      <li className={css(styles.basic)}> booking@shiftedrecording.com </li>

    </ul>
    <ul className={css(styles.mobileFull)}>
      <li className={css(styles.primary)}> About Us </li>
      <li className={css(styles.link)}> <a className={css(styles.link)} href="https://www.instagram.com/shiftedrecording/"> Instagram  </a></li>
      <li className={css(styles.link)}> <a className={css(styles.link)} href="https://www.facebook.com/shiftedrecording/"> Facebook  </a></li>
    </ul>
  </div>
)
const styles = StyleSheet.create({
    wrapper: {
      textTransform: 'uppercase',
      fontWeight: 400,
      background: 'black',
      color: 'white',
      display: 'table',
      width: "100%",
      padding: "30px",
      fontFamily: "MinionPro",
      letterSpacing: "1px",
    },
    basic: {
      listStyleType: "none",
    },
    primary: {
      marginTop: 10,
      fontFamily: "KnockoutBan",
      fontWeight: 400,
      letterSpacing: "3px",
      color: "white",
      fontSize: "36px",
      textTransform: "uppercase",
      listStyleType: "none",
    },
    mobileFull: {
      width: '100%',
      textAlign: "center",
      listStyleType: "none",
      padding: 0,
      margin: 0,
    },
    link: {
      color: "#337ab7",
      textDecoration: 'none',
      height: '24px',
      marginTop: '20px'
    }
});

export default Footer

// git token: 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
