// Imports
import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <p>
    Page not found.
    {' '}
    <Link to="/">Go Home</Link>
  </p>
);

export default NotFound;
