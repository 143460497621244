// Imports
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import styles from './blog-styles.js';
import { StyleSheet, css } from 'aphrodite';
import Impact from './../home/Impact.js';
// App Imports
//import { actionBlogsFetch, actionBlogsFetchIfNeeded } from './api/actions';
import Loading from '../common/Loading';
// Component
import RecentBlogs from './RecentBlogs.js';
const BlogsContainer = () => {


  // render
  return (
    <div className={css(styles.blogsection2)}>
      <Helmet>
      </Helmet>
      <Impact context='blog'/>
      <RecentBlogs />
    </div>
  )
}

export default BlogsContainer
