export default [
    { name: 'Francis and the Lights'},
    { name: 'Jeremy Zucker'},
    { name: 'Toro y Moi'},
    { name: 'Cautious Clay'},
    { name: 'J Balvin'},
    { name: 'mxmtoon'},
    { name: 'Sophie Cates'},
    { name: 'Lauv'},
    { name: 'Dermott Kennedy'},
    { name: 'Junior Varsity'},
    { name: 'Cavetown'},
    { name: 'Ella Jane'},
    { name: 'Richie Quake'},
    { name: 'Tim Atlas'},
    { name: 'Emeryld'},
    { name: 'Jordana'},
    { name: 'Claud'},
    { name: 'Hadji Gaviota'},
    { name: 'HOKO'},
    { name: 'Daydream Masi'},
    { name: 'Too Many Zooz'},
    { name: 'X Ambassadors'},
    { name: 'Jill Scott'},
    { name: 'Clams Casino'},
    { name: 'SG Lewis'},
    { name: 'Cage the Elephant'},
    { name: 'prettyboyshav'},
    { name: 'SZA'},
    { name: 'Alisa Nappa'},
    { name: 'Desiigner'},
    { name: 'Jidenna'},
    { name: 'Odesza'},
    { name: 'Baauer'},
    { name: 'Towkio'},
    { name: 'AJ Tracy'},
    { name: 'Snakehips'},
    { name: 'LPX'},
    { name: 'St. Lucia'},
    { name: 'Lion Babe'},
    { name: 'Smino'},
    { name: 'Alunageorge'},
    { name: 'Khary'},
    { name: 'Yaeji'},
    { name: 'StarRo'},
    { name: 'Jain'},
    { name: 'Blonde Redhead'},
    { name: 'Ammar Malik'},
    { name: 'Peter CottonTale'},
    { name: 'Nate Fox'},
    { name: 'Lawrence'},
    { name: 'Hundredth'},
    { name: 'Cruel Youth'},
    { name: 'Masego'},
    { name: 'S Carey'},
    { name: 'Col3trane'},
    { name: 'Thutmose'},
    { name: 'Julian Lamadrid'},
    { name: 'Bibi Bourelly'},
    { name: 'Lil Tracy'},
    { name: "L'Freaq"},
    { name: 'Ben Kessler'},
    { name: 'Noname'},
    { name: 'Big Wild'},
    { name: 'Sheck Wes'},
    { name: 'Bob Moses'},
    { name: 'Mac Ayres'},
    { name: 'Tekashi 69'},
    { name: 'Eric Krasno'},
    { name: 'Adam Deitch'},
    { name: 'The Motet'},
    { name: 'Monte Booker'},
    { name: 'JIL'},
    { name: 'AbJo'},
    { name: 'LAIKO'},
    { name: 'Lege Kale'},
    { name: 'Kyle Morton'},
    { name: 'John Hill'},
    { name: 'Allen Stone'},
    { name: 'RØMANS'},
    { name: 'Sammy Adams'},
    { name: 'Break Science'},
    { name: 'Lettuce'},
    { name: 'Cigarettes After Sex'},
    { name: 'Knox Fortune'},
    { name: 'Walking Shapes'},
    { name: 'Michael Blume'},
    { name: 'Nosaj Thing'},
    { name: 'Asukal'},
    { name: 'Bohan Pheonix'},
    { name: 'Rome Fortune'},
    { name: 'Gramatik'},
    { name: 'Cakes Da Killa'},
    { name: 'Ro Ransom'},
    { name: 'Linus Hablot'},
    { name: 'Kidepo'},
    { name: 'Son Little'},
    { name: 'Sean McVerry'},
    { name: 'Armani White'},
    { name: 'Shameik Moore'},
    { name: 'C3 Mgmt'},
    { name: 'Songs Publishing',},
    { name: 'Red Light Mgmt'},
    { name: 'Ultra Records'},
    { name: 'Def Jam Records'},
    { name: 'Sony / ATV Publishing'},
    { name: 'S-Curve Records'},
    { name: 'Fool’s Gold Records'},
    { name: 'Domino Records'},
    { name: 'Universal Music Group'},
    { name: 'VICE'},
    { name: 'Roc Nation'},
    { name: 'Discovery Channel'},
    { name: 'The Other Two (HBO Max)'},
    { name: 'General Mills'},
    { name: 'GAP'},
    { name: 'Sony UK'}
  ];
