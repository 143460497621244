import React from 'react'
import { Helmet } from 'react-helmet'
import { StyleSheet, css } from 'aphrodite/no-important';
import StaffConstant from '../constants/staff.constant.js';


const PopulateStaff = () => {
  return StaffConstant.map((g, i) => {
    return (
      <li className={css(styles.staff)} key={`staff-${i}`}>
        <div className={css(styles.imageWrap)}>
          <img className={css(styles.image)} width={412} height={500} src={g.img} alt={g.name}/>
        </div>
        <ul className={css(styles.titles)}>
          <li className={css(styles.name)}> { g.name }</li>
          <li className={css(styles.title)}> { g.titles.join(', ') } </li>
        </ul>
      </li>
    )
  })
}
const Staff = () => {
  let staff = PopulateStaff();
  return (
    <div id='staff' className={css(styles.wrapper)}>
      <h1 className={css(styles.header)}> Staff </h1>
      <ul className={css(styles.list)}>
        { staff }
      </ul>
    </div>
  )
}
const styles = StyleSheet.create({
    wrapper: {
      padding: "50px",
      background: "url(/images/webp/nice-bg.webp)",
    },
    header: {
      color: "black",
      textAlign: "center",
      fontFamily: "Akkurat",
      fontWeight: 400,
      fontSize: "36px",
    },
    list: {
      padding: "0px",
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: 'space-between',
    },
    staff: {
      alignItems: "center",
      justifyContent: "center",
      whiteSpace: "nowrap",
      fontFamily: "Akkurat",
      fontWeight: 400,
      width: "22%",
      '@media (max-width: 767px)': {
          width: '100%',
      },
      display: "inline-block",
      position: "relative",
    },
    imageWrap: {
      left: 0,
      bottom: "10px",
      overflow: "hidden",
    },
    image: {
      width: "100%",
      height: 'auto',
      position: "relative",
      bottom: "2px",
    },
    titles: {
      padding: 0,
      display: "table",
      position: "relative",
      top: "10px",
      left: "10px",
      '@media (max-width: 767px)': {
          top: 0,
      }
    },
    name: {
      listStyleType: 'none',
    },
    title: {
      listStyleType: 'none',
      display: 'inline-block',
      marginRight: '5px',
    },
});

export default Staff
