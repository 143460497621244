// Imports
import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
// App Imports
import RoomConstants from '../constants/rooms.constant.js';
import { StyleSheet, css } from 'aphrodite/no-important';
import Slider from './slider.js'
import { Helmet } from 'react-helmet'

// Component
const Room = ({ match: { params: { room } } }) => {
  // state
  let RoomObject = RoomConstants.find(obj => obj.url === room )

  const { loading, details } = useSelector(state => state.blog)
  let { specs, gear } = RoomObject
  // render
  return (
    <div className={css(styles.wrapper)}>
      <Helmet>
        <title> Best Brooklyn Recording Studio | Shfited Recording { RoomObject.title } | Top Brooklyn Music Producers </title>
      </Helmet>
      <Slider room={RoomObject} />
      <div className={css(styles.glance)}>
        <h4 className={css(styles.title)}> At A Glance </h4>
        <article className={css(styles.description)}> { RoomObject.overview } <hr /> </article>
        <div className={css(styles.attrs)}>
          <div className={css(styles.attrTitle)}>Specifications</div>
          <div className={css(styles.attrList)}>
            {
              Object.entries(specs).map(([key, val]) =>
                <div key={key + val}> {key.split('_').join(' ')}: {val} </div>
              )
            }
          </div>
        </div>
        { gear ?
        <div className={css(styles.attrs)}>
          <div className={css(styles.attrTitle)}>Gear</div>
          <div className={css(styles.attrList)}>
            { gear.map(item => <div key={item}> { item } </div> )  }
          </div>
          <hr />
        </div>
         : null
        }
        <div className={css(styles.attrs)}>
          <div className={css(styles.attrTitle)}></div>
          <div className={css(styles.attrList)}>

          </div>
        </div>
      </div>
    </div>
  )
}
const styles = StyleSheet.create({
    wrapper: {
      margin: 0,
      padding: 0
    },
    glance: {
      background: 'white',
      fontFamily: 'Akkurat',
      textTransform: 'uppercase',
      padding: '50px',
      fontWeight: 400,
      textAlign: 'center',
      fontSize: '14px',
    },
    title: {
      fontSize: 36,
      margin: '10px 0px',
      fontFamily: 'KnockoutBan'
    },
    description: {
      marginTop: '20px',
      fontFamily: 'Akkurat',
      width: '50%',
      margin:' 0 auto',
      '@media (max-width: 767px)': {
        width:"100%"
      }
    },
    attrs: {
      textAlign: 'left',
      display: 'table',
      width: '50%',
      margin: '0 auto',
      '@media (max-width: 767px)': {
        width:"100%"
      }
    },
    attrTitle: {
      fontFamily: 'Akkurat',
      float: 'left',
      width: '30%',
      padding: '20px 0px',
      '@media (max-width: 767px)': {
        width:"50%"
      }
    },
    attrList: {
      width: '70%',
      float: 'left',
      padding: '20px 0px',
      '@media (max-width: 767px)': {
        width:"50%"
      }
    }

});
export default Room
