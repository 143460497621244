// Imports
import React from 'react'
import { Helmet } from 'react-helmet'
import { StyleSheet, css } from 'aphrodite';

import Impact from './Impact';
import NewsSlider from './NewsSlider';
import Community from './Community';
import Discord from './Discord';
import Project from './Project';
import Space from './Space';
import GearList from './GearList';
import Rooms from './Rooms';
import Staff from './Staff';
import Footer from './Footer';

const Home = () => (
  <div>
    <Helmet>
      <title>Shifted Recording | Brooklyn Recording Studios | Brooklyn Music Producers </title>
    </Helmet>

    <section id="static">
      <Impact />
      <Project />
      <Space />
      <GearList />
      <Rooms />
      <Community />
      <Footer />
    </section>
  </div>
)

const styles = StyleSheet.create({

});

export default Home
