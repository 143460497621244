import React from 'react'
import { Helmet } from 'react-helmet'
import { StyleSheet, css } from 'aphrodite/no-important';
import NewsConstants from '../constants/news.constant.js';

// Lets Clone The Oldest Item Behind The Newest
// NewsConstants.reverse().unshift(NewsConstants[NewsConstants.length - 1])

const MARGIN_CONSTANT = 15; //this x2 must be divisible by 10
const MCOD = 6; // Max Cards On Display
const SPEED_CONSTANT = 10 // How frequently the animation should update... basically FPS // lower number = more fps
let BACK_CLONES = NewsConstants.slice(-(MCOD)); // The argument slice takes needs to be the max cards to possibly display at once
let FRONT_CLONE = [NewsConstants[NewsConstants.length - 1]]; // and clone the last item to the front
let CardsToMap = [...FRONT_CLONE, ...NewsConstants, ...BACK_CLONES];

class NewsSlider extends React.Component {
  animateSlide(overide){
    if(overide) this.setState({animating: true, autoPlay: false})
    // overide positive (1) for forward movement, and negative (-1) for backward movement
    let s = this.state;
    let left = this.state.slideIndex > 0 ? -(this.state.slideIndex * this.getCardWidth() + ( this.state.slideIndex * MARGIN_CONSTANT * 2 )) : 0
    if( overide < 0 && this.state.slideIndex - 1 < 0) {
      left = -((CardsToMap.length - 1 - MCOD) * this.getCardWidth() + (MARGIN_CONSTANT * 2 * (CardsToMap.length - 1 - MCOD)))
    }
    //console.log('Left Position? ', left)
    if(overide || (s.animating && s.autoPlay)){
      let timer = setInterval(() => {
        if(!this.refs.slider) {
          clearInterval(timer);
          return;
        }
        (overide < 0 && this.refs && this.refs.slider) ? this.refs.slider.style.left = ( left += 10 ) + "px" : this.refs.slider.style.left = ( left -= 10 ) + "px";
        // console.log('left? ', left, s.nextAnimationDestination)
        if ( left == s.nextAnimationDestination ) {
          clearInterval( timer );
          let nextSlide = overide < 0 ? s.slideIndex - 1 : s.slideIndex + 1;
          if(s.autoPlay && s.numberOfVisibleCards + s.slideIndex  + 1 > s.cards.length - 2 - (MCOD - s.numberOfVisibleCards)) nextSlide = 0
          if(overide < 0 && s.numberOfVisibleCards + s.slideIndex  - 1 > s.cards.length - 2 - (MCOD - s.numberOfVisibleCards)) nextSlide = 0
          if(overide > 0 && s.numberOfVisibleCards + s.slideIndex  + 1 > s.cards.length - 2 - (MCOD - s.numberOfVisibleCards)) nextSlide = 0
          if(nextSlide < 0) nextSlide = CardsToMap.length - 2 - MCOD
          let nextMarg = ((s.slideIndex + 2) * MARGIN_CONSTANT * 2)
          let nextCardW = (s.slideIndex + 2) * this.getCardWidth()
          if(overide < 0){
            nextMarg = ((s.slideIndex) * MARGIN_CONSTANT * 2)
            nextCardW =(s.slideIndex ) * this.getCardWidth()
          }
          let nextAnimationDestination = nextSlide === 0 ? -(this.getCardWidth() + MARGIN_CONSTANT * 2) : -(nextMarg + nextCardW)
          if(overide < 0 && nextSlide === CardsToMap.length - 2 - MCOD) {
            console.log('Over Ride', nextSlide)
            nextAnimationDestination = -((CardsToMap.length - 1 - MCOD) * this.getCardWidth() + (MARGIN_CONSTANT * 2 * (CardsToMap.length - 1 - MCOD)))
          };
          this.setState({
            animating: false,
            slideIndex: nextSlide,
            nextAnimationDestination: nextAnimationDestination
          })
        }
      }, SPEED_CONSTANT);
    }
  }

  nextCard(direction){
    let s = this.state;
    if(s.animating) return
    if(direction < 0 ){
      if(s.slideIndex - 1 == 0) this.setState({nextAnimationDestination: 0}, () => this.animateSlide(direction))
      if(s.slideIndex - 1 < 0 && s.slideIndex - 1 !== 0){
        let nextAnimationDestination = -((CardsToMap.length - 1 - MCOD) * this.getCardWidth() + (MARGIN_CONSTANT * 2 * (CardsToMap.length - 1 - MCOD))) + (this.getCardWidth() + MARGIN_CONSTANT * 2)
        this.setState(
          { nextAnimationDestination: nextAnimationDestination },
          () => {this.animateSlide(direction)}
        )
      } else if (this.state.slideIndex - 1 > 0) {
        let nextAnimationDestination = -(((s.slideIndex - 1) * MARGIN_CONSTANT * 2) + ((s.slideIndex - 1) * this.getCardWidth()))
        //console.log('Next Anim', nextAnimationDestination);
        this.setState(
          { nextAnimationDestination: nextAnimationDestination },
          () => {this.animateSlide(direction)}
        )      }
    } else {
      if(!this.state.animating) this.animateSlide(direction)
    }
  }

  togglePlay(){
    this.setState({ autoPlay: !this.state.autoPlay })
  }

  getCardWidth(getNumVisible){
    if(typeof window === "undefined") return 250;
    let numcards;
    let width = window.innerWidth;
    if(width < 415) numcards = 1;
    if(width >= 415 && width < 769) numcards = 2;
    if(width >= 769 && width < 1025) numcards = 4;
    if(width >=1025 && width <1400) numcards = 5;
    if(width > 1400) numcards = 6;
    if(getNumVisible) return numcards;
    // Determine # of Cards
    return Math.round((width / numcards) / 10) * 10 - ( MARGIN_CONSTANT * 2);
  }

  buildCards(){
    let cw = window ? this.getCardWidth() : 250;
    return CardsToMap.map((item, i) => {
      let stringifiedCredits = '';
      for (const key in item.credits){
        stringifiedCredits = key.split('_').join(' ') + ' ' + item.credits[key].join (' + ')
      }
      return (
        <div style={{width: cw }} className={css(styles.cardWrapper)} key={`card-${i}`}>
          <img src={ item.img } height="240" width="240" className={css(styles.image)} alt={ `${item.artist} ${item.title}` } />
          <div className={css(styles.cardInfo)}>
            <p className={css(styles.date)}> { item.date } </p>
            <h5 className={css(styles.artistName)}> { item.artist }</h5>
            <p className={css(styles.title)}> { item.title } </p>
          </div>
          <div className={css(styles.credits)}>
            <div className={css(styles.borderTop)}></div>
            { stringifiedCredits }
          </div>
        </div>
      )
    })
  }

  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      slideIndex: 1,
      cards: [],
      autoPlay: true,
      animating: false,
      nextAnimationDestination: null,
      numberOfVisibleCards: undefined,
      width: undefined
    };
    this.resize = this.resize.bind(this);
    this.togglePlay = this.togglePlay.bind(this);
    this.nextCard = this.nextCard.bind(this);
  }

  resize = () => {
    if(this.state.width != window.innerWidth){
      window.location.reload();
    }
  }

  componentDidMount(){
    window.addEventListener('resize', this.resize)
    this.refs.slider.style.left = -(this.getCardWidth() + MARGIN_CONSTANT * 2) + 'px'
    this.setState({
      width: window.innerWidth,
      cards: this.buildCards(),
      numberOfVisibleCards: this.getCardWidth(true),
      nextAnimationDestination: -((this.getCardWidth() + MARGIN_CONSTANT * 2) * 2)
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
    this.setState({animating: true})
  }

  componentDidUpdate(){
    let s = this.state;
    if(s.animating && s.autoPlay){
      this.animateSlide()
    }
    if(this.state.autoPlay && !this.state.animating ){
      setTimeout(() => {
        if( this.state.autoPlay && !this.state.animating && this.refs.slider) this.setState({ animating: true })
      }, 3000)
    }
  }
  render() {
    const cards = this.state.cards
    return (
      <div className={css(styles.news)}>
        <div id="#slider" ref='slider' className={css(styles.sliderTrack)}>
          { cards }
        </div>
        <div className={css(styles.controlWrapper)}>
        <span className={css(styles.controlItem)}> <img src={'/images/svg/arrow-left-short.svg'} width='16' height='16' alt="slider control arrow left" onClick={() => this.nextCard(-1)} /> </span>
        <span className={css(styles.controlItem)}> { this.state.autoPlay ? <img src="/images/svg/pause-fill.svg" alt="slider pause"onClick={this.togglePlay} width='16' height='16'/> : <img src={ '/images/svg/play-fill.svg' } alt="slider play"width='16' height='16' onClick={this.togglePlay} /> } </span>
        <span className={css(styles.controlItem)}> <img src={'/images/svg/arrow-right-short.svg'} width='16' height='16' alt="slider control arrow left" onClick={() => this.nextCard(1)} /> </span>
        </div>
      </div>
    )
  }
}

const styles = StyleSheet.create({
    news: {

    },
    sliderTrack: {
      display: "inline-flex",
      flexDirection: "row reverse",
      padding: '50px 0px 50px 0px',
      background: '#dee3e6',
      position: 'relative',
      minHeight: '420px',
      overflow: 'hidden'
    },
    cardWrapper: {
      margin: '0px 15px',
      flex: "0 0 auto",
      display: "flex",
      flexDirection: "column",
      textAlign: 'center',
      background: 'white',
      color: 'black',
      fontFamily: "Akkurat",
      fontSize: '12px',
      textTransform: 'uppercase',
      borderRadius: '10px',
      minHeight: '420px',

     '@media (min-width: 1920px)': {
       height:"calc(42vh - 100px)"
     }
    },
    image: {
      borderRadius: "10px 10px 0px 0px",
      width: "100%",
      display: "block",
      objectFit: "cover",
      height: "auto",
    },
    cardInfo: {

    },
    date: {
      fontStyle: "italic",
      paddingTop: '10px',
      margin: 0,
    },
    artistName: {
      fontFamily: "KnockoutBan",
      fontSize: "24px",
      letterSpacing: ".5px",
      position: 'relative',
      padding: '15px',
      margin: '0px',
    },
    credits: {
      fontStyle: 'italic',
      textTransform: 'uppercase',
      fontSize: "12px",
      position: 'relative',
      padding: '15px',
    },
    borderTop: {
      borderTop: "1px solid #eeeeee",
      position: "absolute",
      width: "80%",
      top: 0,
      left: "10%",
    },
    controlWrapper: {
      boxSizing: 'border-box',
      padding: "0px 40px",
      height: '50px',
      width: '100%',
      background: '#dee3e6',
      display: 'flex',
      justifyContent: 'space-between'
    },
    controlItem: {
      cursor: 'pointer',
      height: 'fit-content'
    }
});

export default NewsSlider
