import React from 'react'
import { Helmet } from 'react-helmet'
import { StyleSheet, css } from 'aphrodite';
const Impact = (props) => {
  return(
    <div id='home' className={css(styles.impact)}>
      <img src="https://shiftedrecording.com/logo-white-green.svg" width={500} height={309} alt="Shifted Logo" className={css(styles.image)} />
      {
        (props.context !== 'blog')
        ?<h1 className={css(styles.tag)}>   Brooklyn's Recording Studio, Reinvented for the Modern Artist   </h1>
        : null
      }
    </div>
  )
}

const styles = StyleSheet.create({
    impact: {
      background: `url(${'/images/webp/speaker.webp'})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '100%',
      backgroundSize: 'cover',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      height: '40vh'
    },
    image: {
      width: 400,
      margin: '0 auto',
      display: 'block',
    },
    tag: {
      display: 'block',
      position: 'relative',
      textAlign: 'center',
      bottom: '100px',
      color: 'white',
      width: '100%',
      fontFamily: "Akkurat",
      fontSize: '1.17em',
      //fontWeight: 400,
    },
});

export default Impact
