// Imports
import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { StyleSheet, css } from 'aphrodite/no-important';

function styleLinks(direction) {
  const links = [2, 3, 5];
  if (direction === 'going-down') {
    links.forEach((n) => {
      document.getElementById(`link${n}`).classList.remove('animate-color-white');
      document.getElementById(`link${n}`).classList.add('animate-color-black');
    });
  } else {
    links.forEach((n) => {
      document.getElementById(`link${n}`).classList.remove('animate-color-black');
      document.getElementById(`link${n}`).classList.add('animate-color-white');
    });
  }
}

function attachScrollListener() {
  const height = 500; let navbar = null; let alreadyAnimated = false; let
    top = window.scrollY;
  window.addEventListener('scroll', () => {
    if (navbar == null) navbar = document.getElementById('navbar');
    top = window.scrollY;
    if (top >= height) {
      navbar.classList.remove('animate-trans');
      navbar.classList.add('animate-white');
      styleLinks('going-down');
      alreadyAnimated = true;
    } else if (top < height && alreadyAnimated) {
      navbar.classList.remove('animate-white');
      navbar.classList.add('animate-trans');
      styleLinks('going-up');
      alreadyAnimated = false;
    }
  });
}
function shouldStartBlack() {
  if(typeof window !== "undefined"){
    if(window.location.pathname == '/blog') return true;
  }
  //if(window.location.)
}
const Layout = ({ children }) => {
  if (typeof window !== 'undefined') attachScrollListener();
  return (
    <div className={css(styles.appwrap)}>
        <header id="navbar" className={css(styles.navbar)}>
        <div className={css(styles.leftLinks)}>
          <a  href="spotify:playlist:3WN2sMfg23KyZmmV39esk9"
              className={css(styles.logoLink)}
              target="_blank">
                <img src='/images/webp/spotify-logo.webp'  width='32px' alt='spotify link' className={css(styles.navLogo)} />
          </a>
          <a  href='https://discord.gg/y7MUXdP7Kj'
              className={css(styles.logoLink)}
              target="_blank">
                <img src='/images/webp/discord-logo.webp' alt='discord link' width='32px' className={css(styles.navLogo)} />
          </a>
          <a  href='https://www.instagram.com/shiftedrecording/?hl=en'
              className={css(styles.logoLink)}
              target="_blank">
                <img src='/images/webp/instagram-logo.webp' width='32px' alt='instagram link' className={css(styles.navLogo)} />
          </a>
        </div>
        <div className={css(styles.rightLinks)}>
          <Link id="link2" className={css(styles.item)} to="/#space">Space</Link> &nbsp;
          <Link id="link3" className={css(styles.item)} to="/#community">Community</Link> &nbsp;
          <a className={css(styles.item)} id="link5" href="spotify:playlist:3WN2sMfg23KyZmmV39esk9" target="_blank" >Reel</a> &nbsp;
        </div>
        </header>
      <section>
        { children }
      </section>
    </div>
  );
};

const is = {
  position: 'absolute',
};

const styles = StyleSheet.create({
  navbar: {
    position: 'fixed',
    width: '100%',
    zIndex: 99,
    display: 'flex',
    justifyContent: 'space-between',
  },
  green: {
    color: '#64d06d !important',
  },
  leftLinks: {
    marginLeft: 4,
    display: 'flex',
    alignItems: 'center',
  },
  rightLinks: {
    position: 'relative',
    top: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  logoLink: {
    textDecoration: "none",
    outline: "none",
    border: "0px none transparent"
  },
  navLogo:{
    width: "32px",
    height: "32px",
    marginRight: "4px",
    textDecoration: "none",
    textDecoration: "none",
    outline: "none",
    border: "0px none transparent"
  },
  item: {
    cursor: 'pointer',
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: 'white',
    padding: '8px',
    fontFamily: 'KnockoutBan',
    letterSpacing: '2px',
    fontSize: '24px',
  },
  black: {
    color: 'black !important'
  }
});
export default Layout;
