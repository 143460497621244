import React from 'react'
import { Helmet } from 'react-helmet'
import { StyleSheet, css } from 'aphrodite';
class MailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      phone: '',
      email: '',
      message: '',
      sent: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event, field) {
    let newState = Object.assign({}, this.state)
    newState[field] = event.target.value;
    this.setState(newState);
  }

  handleSubmit(event) {
    this.setState({sent: 'SENDING'});
    event.preventDefault();

    fetch('/submit-message', {
      method: "POST",
      body: JSON.stringify(this.state),
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => {
      if(res.status === 200) this.setState({sent: "SENT"})
    })
    .catch(err => console.log('err', err.message))
  }

  render() {
    let s = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className={css(styles.close)} onClick={() => this.props.closeModal(s.sent)}> <img src="/images/svg/plus.svg" width='24' height='24' /></div>
        {
          this.state.sent === "SENT" ?
          <div>
            <h1> Success! You'll hear from us soon. </h1>
          </div>
          :
          <div>
            <input className={css(styles.input)} required placeholder="Name*" type="text" value={this.state.name} onChange={(e) => this.handleChange(e, 'name')} />
            <input className={css(styles.input)} required placeholder="Email*"type="email" value={this.state.email} onChange={(e) => this.handleChange(e, 'email')} />
            <input className={css(styles.input)} placeholder="Phone Number"type="tel" value={this.state.phone} onChange={(e) => this.handleChange(e, 'phone')} />
            <textarea className={css(styles.textarea)} required placeholder="What should we know before we speak?" value={this.state.message} onChange={(e) => this.handleChange(e, 'message')} />
            <input className={css(styles.button)} type="submit" value="Submit" /> { this.state.sent === 'SENDING' ? <span> Sending... </span> : null }
          </div>
        }
      </form>
    );
  }
}
const styles = StyleSheet.create({
    input: {
      width: "100%",
      borderRadius: "2px",
      border: "none",
      borderBottom: "1px solid #dadada",
      outline: 0,
      marginTop: "10px",
      fontFamily: "Akkurat",

    },
    textarea: {
      width: "100%",
      borderRadius: "2px",
      border: "none",
      borderBottom: "1px solid #dadada",
      outline: 0,
      marginTop: "10px",
      fontFamily: "Akkurat",
      resize: 'none',
    },
    button: {
      background: "#4c7c5f",
      color: "white",
      fontFamily: "KnockoutBan",
      letterSpacing: "2px",
      textTransform: "uppercase",
      border: "none",
      padding: "10px",
      cursor: "pointer",
    },
    close: {
      background: "0 0",
      borderRadius: "3px",
      color: "#bbb",
      fontSize: "26px",
      fontWeight: 400,
      height: "30px",
      lineHeight: "26px",
      position: "absolute",
      right: "3px",
      textAlign: "center",
      top: "3px",
      width: "30px",
      transform: 'Rotate(45deg)'
    }
});
export default MailForm
